<script setup>
defineOptions({
    inheritAttrs: false
})
const props = defineProps({
    label: {
        type: String,
        default: null
    },
    outerClass: {
        type: String,
        default: ''
    },
    errors: {
        type: Array,
        default: () => []
    },
});
const model = defineModel({
    type: String,
    required: true,
});
// defineEmits(['update:modelValue']);
</script>

<template>
    <div class="relative flex-auto">
        <label
            class="absolute max-w-full px-1 overflow-hidden text-xs font-semibold bg-white text-secondary left-4 -top-2 whitespace-nowrap text-ellipsis">
            {{ label }}
        </label>
        <input v-model="model"
            v-bind="$attrs"
            class="p-3 w-full border border-[#919EAB33] rounded-lg overflow-hidden">
        <div v-if="errors.length" class="text-xs text-red-500">{{ errors }}</div>
    </div>
</template>

<style scoped>

</style>
